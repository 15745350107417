import { IUserMilestone } from '../../IUserMilestone';

/**
 * Retailer has seen the PREPAID_CARDS_RESTRICTED_MODAL promo modal *
 * @deprecated
 */
export const PREPAID_CARDS_RESTRICTED_MODAL_SEEN =
  'PREPAID_CARDS_RESTRICTED_MODAL_SEEN' as IUserMilestone.RetailerMilestone;

export const Extensions = {
  deprecated: true,
};
