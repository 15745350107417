import { useMilestone } from "@faire/retailer-visitor-shared/stores/domain/useMilestone";
import { FIRST_ORDER_INCENTIVE_AUTO_EXTEND_MODAL_DISMISS } from "@faire/web-api--source/indigofair/data/IUserMilestone/RetailerMilestone/FIRST_ORDER_INCENTIVE_AUTO_EXTEND_MODAL_DISMISS";
import { FIRST_ORDER_INCENTIVE_MODAL_DISMISS } from "@faire/web-api--source/indigofair/data/IUserMilestone/RetailerMilestone/FIRST_ORDER_INCENTIVE_MODAL_DISMISS";
import { LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS } from "@faire/web-api--source/indigofair/data/IUserMilestone/RetailerMilestone/LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS";
import { useCallback } from "react";

export const useDismissFOIModalMilestones = () => {
  const {
    count: foiModalDismissCount,
    incrementCount: incrementFoiModalDismissCount,
    setHasOccurred: setHasFOIModalDismissed,
  } = useMilestone(FIRST_ORDER_INCENTIVE_MODAL_DISMISS);

  const {
    count: autoExtendFoiModalDismissCount,
    incrementCount: incrementAutoExtendFoiModalDismissCount,
    setHasOccurred: setHasAutoExtendModalDismissed,
  } = useMilestone(FIRST_ORDER_INCENTIVE_AUTO_EXTEND_MODAL_DISMISS);

  const {
    count: lastChanceFoiModalDismissCount,
    incrementCount: incrementLastChanceFoiModalDismissCount,
    setHasOccurred: setHasLastChanceModalDismissed,
  } = useMilestone(LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS);

  const dismissFirstOrderIncentiveModal = useCallback(() => {
    setHasFOIModalDismissed();
    incrementFoiModalDismissCount();
  }, [setHasFOIModalDismissed, incrementFoiModalDismissCount]);

  const dismissLastChanceFOIModal = useCallback(() => {
    setHasLastChanceModalDismissed();
    incrementLastChanceFoiModalDismissCount();
  }, [setHasLastChanceModalDismissed, incrementLastChanceFoiModalDismissCount]);

  const dismissFOIAutoExtendModal = useCallback(() => {
    setHasAutoExtendModalDismissed();
    incrementAutoExtendFoiModalDismissCount();
  }, [setHasAutoExtendModalDismissed, incrementAutoExtendFoiModalDismissCount]);

  return {
    foiModalDismissCount,
    lastChanceFoiModalDismissCount,
    autoExtendFoiModalDismissCount,
    dismissFirstOrderIncentiveModal,
    dismissLastChanceFOIModal,
    dismissFOIAutoExtendModal,
  };
};
