import { request } from '@faire/web-api--source';
import { IBrandViewV2Response } from '@faire/web-api--source/indigofair/data/IBrandViewV2Response';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  load_upcoming_market_promo: boolean | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (brandToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v2/brand-view/${brandToken}${queryString}`;
};

export const build = (
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/brand-view/:brand_token';

/**
 * Product Area: BRAND_PAGE
 */
function execute(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IBrandViewV2Response>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IBrandViewV2Response>>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrandViewV2Response>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrandViewV2Response | SuccessResponse<IBrandViewV2Response>> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IBrandViewV2Response>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v2/brand-view/brandToken/get';
export default execute;
