import {
  optimizeBannerDesktopAndXlarge,
  optimizeBannerMobile,
  optimizeBannerTablet,
} from "@faire/retailer-visitor-shared/views/brand/BrandHeader/optimizeBannerImageUrl";
import {
  isManuallyCroppedImage,
  optimizeImageUrl,
} from "@faire/web--source/common/images/optimizeImages";
import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";

// Keep in descending order for media queries
const POPULAR_DISPLAY_PIXEL_RATIOS = [3, 2, 1];

// Keep in ascending order for media queries
const BANNER_WIDTHS = [
  MIN_WIDTH_BREAKPOINTS.TABLET - 1,
  MIN_WIDTH_BREAKPOINTS.TABLET,
  MIN_WIDTH_BREAKPOINTS.TABLET + 1,
  MIN_WIDTH_BREAKPOINTS.DESKTOP - 1,
  MIN_WIDTH_BREAKPOINTS.DESKTOP,
];

export const buildBannerImageSrcSet = (
  image: IImage,
  isSSR: boolean = false
) => {
  if (isManuallyCroppedImage(image)) {
    return {
      src: optimizeImageUrl(image, isSSR, {
        height: 240,
      }),
      srcset: undefined,
      sizes: undefined,
    };
  }

  const desktopAndAboveSrc = optimizeBannerDesktopAndXlarge(image, isSSR);
  const tabletSrc = optimizeBannerTablet(image, isSSR);
  const mobileSrc = optimizeBannerMobile(image, isSSR, 120);

  const bannerWidthSrcMapping = BANNER_WIDTHS.map((width) => {
    if (width >= MIN_WIDTH_BREAKPOINTS.DESKTOP) {
      return { width, src: desktopAndAboveSrc };
    }
    if (width >= MIN_WIDTH_BREAKPOINTS.TABLET) {
      return { width, src: tabletSrc };
    }
    return { width, src: mobileSrc };
  });

  const srcset = bannerWidthSrcMapping
    .map(({ width, src }) => `${src} ${width}w`)
    .join(", ");

  const dprSizes = POPULAR_DISPLAY_PIXEL_RATIOS.map(
    (dpr) => `(-webkit-min-device-pixel-ratio: ${dpr}) ${100 / dpr}vw`
  ).join(", ");

  return {
    src: desktopAndAboveSrc,
    srcset,
    sizes: `${dprSizes}, 100vw`,
  };
};
