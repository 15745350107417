import { usePromoModalStore } from "@faire/retailer-visitor-shared/stores/ui/__internal__/usePromoModalStore";
import { useShouldNotShowFaireDirectIncentiveModals } from "@faire/retailer-visitor-shared/stores/ui/__internal__/useShouldNotShowFaireDirectIncentiveModals";
import { clock } from "@faire/web--source/common/clock";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { Storage } from "@faire/web--source/common/Storage";
import { PromoModalType } from "@faire/web-api--source/indigofair/data/PromoModalType";
import { useDismissFOIModalMilestones } from "@faire/retailer-visitor-shared/hooks/firstOrderIncentive/useDismissFOIModalMilestones";
import { useCallback } from "react";
import { useSettingPersonalizedFoiV2 } from "@faire/retailer-visitor-shared/settings/useSettingPersonalizedFoiV2";

// eslint-disable-next-line @faire/no-re-exports
export {
  MOBILE_APP_PROMOTION_ALLOWED_PATHS,
  MOBILE_APP_PROMOTION_BLOCKED_PATHS,
} from "@faire/retailer-visitor-shared/stores/ui/__internal__/usePromoModalStore";

export const PROMO_MODAL_LAST_TIME_SEEN_STORAGE_KEY =
  "PROMO_MODAL_LAST_TIME_SEEN";

const ONE_DAY = 1000 * 60 * 60 * 24;

export const getLastTimeSeen = () => {
  return (
    Number(Storage.local.getItem(PROMO_MODAL_LAST_TIME_SEEN_STORAGE_KEY)) || 0
  );
};

const getDisplayedPromotionRecently = () => {
  return clock.now() - getLastTimeSeen() < ONE_DAY;
};

export const usePromoModalToDisplay = (): {
  modalToDisplay: keyof typeof PromoModalType | null;
  closePromoModal: () => void;
} => {
  const isSSR = useIsSSR();
  // eslint-disable-next-line prefer-const
  let { modalToDisplay, closeModal } = usePromoModalStore();
  const { foiModalDismissCount, lastChanceFoiModalDismissCount, autoExtendFoiModalDismissCount } = useDismissFOIModalMilestones();
  const personalizedFoiV2 = useSettingPersonalizedFoiV2();

  const shouldNotShowFaireDirectIncentiveModals =
    useShouldNotShowFaireDirectIncentiveModals();

  const hasDismissedFoiModal = foiModalDismissCount >= 1 || lastChanceFoiModalDismissCount >= 1 || autoExtendFoiModalDismissCount >= 1;

  const closePromoModal = useCallback(() => {
    // Only record lastTimeSeen when user close the modal to make sure
    // the modal was displayed properly
    Storage.local.setItem(
      PROMO_MODAL_LAST_TIME_SEEN_STORAGE_KEY,
      String(clock.now())
    );
    closeModal();
  }, [closeModal]);

  if (isSSR) {
    modalToDisplay = null;
  }

  if (getDisplayedPromotionRecently()) {
    modalToDisplay = null;
  }

  // Gating Faire Direct modals at component level their conditions are hook based
  if (shouldNotShowFaireDirectIncentiveModals) {
    modalToDisplay = null;
  }

  // we don't render FOI modal if user has dismissed the Conductor one already
  if (personalizedFoiV2 && modalToDisplay === PromoModalType.FIRST_ORDER_INCENTIVE_MODAL && hasDismissedFoiModal) {
    modalToDisplay = null;
  }

  return { modalToDisplay, closePromoModal };
};
