import { useSetting } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES } from "@faire/web-api--source/indigofair/settings/FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES";
import { route as c1CategoryRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId";
import { route as discoverRoute } from "@faire/web-api--source/routes/www/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/www/explore/searchTerm";
import { route as searchRoute } from "@faire/web-api--source/routes/www/search";
import { route as c1SuppliersRoute } from "@faire/web-api--source/routes/www/suppliers/c1SuppliersUrlId";

const DEFAULT_ELIGIBLE_ROUTES = [
  c1SuppliersRoute,
  c1CategoryRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
];

export const useSettingFaireDirectRecurringMarketplaceEligibleRoutes =
  (): string[] =>
    useSetting(
      FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES,
      DEFAULT_ELIGIBLE_ROUTES
    );
