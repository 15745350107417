import { extractImageCropFromTag } from "@faire/web--source/common/images/cropUtils/extractImageCropFromTag";
import { optimizeImageUrlFillSquare } from "@faire/web--source/common/images/optimizeImages";
import { optimizeImageUrl } from "@faire/web--source/common/transform-images";
import { TypographyVariants } from "@faire/web--source/slate/Typography";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";

export type AvatarSize =
  | "xx-small"
  | "x-small"
  | "smaller"
  | "small"
  | "medium"
  | "large"
  | "x-large";

export type AvatarIconPosition =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

export type AvatarBorderStyle = "THIN" | "OUTLINED";

export type IIconData = {
  position: AvatarIconPosition;
  icon: React.ReactNode;
};

export const AvatarSizeToTypographyVariant: Record<
  AvatarSize,
  TypographyVariants | undefined
> = {
  "x-large": "displaySSansSemiBold",
  large: "sectionHeaderSansMedium",
  medium: "sectionHeaderSansMedium",
  small: "paragraphSansMedium",
  smaller: "paragraphSansMedium",
  "x-small": "paragraphSansMedium",
  "xx-small": "labelSansMedium",
};

export const DEGREE_BY_POSITION: Record<AvatarIconPosition, number> = {
  right: 0,
  topRight: 45,
  top: 90,
  topLeft: 135,
  left: 180,
  bottomLeft: 225,
  bottom: 270,
  bottomRight: 315,
};

export const DIMENSION_BY_SIZE: Record<AvatarSize, number> = {
  "xx-small": 24,
  "x-small": 32,
  smaller: 40,
  small: 48,
  medium: 56,
  large: 64,
  "x-large": 96,
};

export const optimizeAvatar = (
  image: Partial<IImage>,
  size: AvatarSize,
  isSSR: boolean
) => {
  const dimension = DIMENSION_BY_SIZE[size];
  if (extractImageCropFromTag(image.tags)) {
    return optimizeImageUrl(image, isSSR, {
      width: dimension,
      height: dimension,
    });
  }
  return optimizeImageUrlFillSquare(image, isSSR, dimension);
};
