import {
  BANNER_HEIGHT_DESKTOP,
  BANNER_HEIGHT_TABLET,
} from "@faire/retailer-visitor-shared/views/brand/BrandHeader/bannerHeightConsts";
import { INNER_MAKER_CONTAINER_MAX_WIDTH } from "@faire/retailer-visitor-shared/views/brand/InnerMakerContainer";
import { optimizeImageUrlFillRectangle } from "@faire/web--source/common/images/optimizeImages";
import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";

const MAX_EXPECTED_MOBILE_WIDTH = 480;

export const optimizeBannerMobile = (
  image: IImage,
  isSSR: boolean,
  bannerHeight: number
) =>
  optimizeImageUrlFillRectangle(
    image,
    isSSR,
    MAX_EXPECTED_MOBILE_WIDTH,
    bannerHeight
  );
export const optimizeBannerTablet = (image: IImage, isSSR: boolean) =>
  optimizeImageUrlFillRectangle(
    image,
    isSSR,
    MIN_WIDTH_BREAKPOINTS.DESKTOP,
    BANNER_HEIGHT_TABLET
  );
export const optimizeBannerDesktopAndXlarge = (image: IImage, isSSR: boolean) =>
  optimizeImageUrlFillRectangle(
    image,
    isSSR,
    INNER_MAKER_CONTAINER_MAX_WIDTH,
    BANNER_HEIGHT_DESKTOP
  );
