import { SettingConst } from '@faire/web-api--source/types';

/**
 * Feature toggle for Insider threshold waiving on opening orders.
 * The experiment will start before the feature toggle is on for pre-bucketing.
 * This will gate the actual experience before the feature launch.
 * PRD: https://www.notion.so/faire/Waive-thresholds-on-opening-orders-17c2efb5c25a80dcbb87cad7aa057c87.
 * Eng Spec: https://www.notion.so/faire/Waive-Thresholds-for-Opening-Orders-TWOO-Eng-Spec-1a82efb5c25a80b7a0aeefb06473da47
 */
export const INSIDER_THRESHOLD_WAIVING_ENABLED_250310 =
  'INSIDER_THRESHOLD_WAIVING_ENABLED_250310' as SettingConst<
    'INSIDER_THRESHOLD_WAIVING_ENABLED_250310',
    boolean,
    'RETAILER'
  >;
