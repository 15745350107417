import { PromoModalType } from '@faire/web-api--source/indigofair/data/PromoModalType';

export namespace IPromoModalInformationResponse {
  /**
   * Return an instance of IPromoModalInformationResponse with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IPromoModalInformationResponse>
  ): IPromoModalInformationResponse =>
    ({
      ...(partial ?? {}),
    }) as IPromoModalInformationResponse;
}

export interface IPromoModalInformationResponse {
  type: keyof typeof PromoModalType | undefined;
}
