import { SettingConst } from '@faire/web-api--source/types';

/**
 * Experiment to give retailers a personalized FOI with $300 cap.
 * https://www.notion.so/faire/Personalized-FOI-V2-1732efb5c25a806cb6add9c96bd370ff
 */
export const PERSONALIZED_FOI_V2_250114 =
  'PERSONALIZED_FOI_V2_250114' as SettingConst<
    'PERSONALIZED_FOI_V2_250114',
    boolean,
    | 'RETAILER'
    | 'RETAILER_ANDROID'
    | 'RETAILER_IOS'
    | 'HOTJAR_RETAILER'
    | 'VISITOR_WEB'
    | 'HOTJAR_VISITOR_WEB'
  >;
