import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/boards"
 *
 * Product Area: BOARDS
 */
export const route = '/boards';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/boards',
  productArea: 'BOARDS',
  pod: 'FAIRE_POD_EVALUATE',
  pillar: 'FAIRE_PILLAR_DISCOVERY',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path() {
  const compiledPath = toPath({});
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (): string => {
  return faireBaseUrl(3000) + path();
};

export default path;
