import { useSetting } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { INSIDER_THRESHOLD_WAIVING_ENABLED_250310 } from "@faire/web-api--source/indigofair/settings/INSIDER_THRESHOLD_WAIVING_ENABLED_250310";
import { INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218 } from "@faire/web-api--source/indigofair/settings/INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218";
import { isBefore } from "date-fns/isBefore";

import { useSettingInsiderWaiveThresholdsEndDate } from "./useSettingInsiderWaiveThresholdsEndDate";

const useSettingEnableInsiderWaiveThresholdsFeatureToggle = (): boolean =>
  useSetting(INSIDER_THRESHOLD_WAIVING_ENABLED_250310, false);
const useSettingEnableInsiderWaiveThresholdsXP = (): boolean =>
  useSetting(INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218, false);

export const useSettingEnableInsiderWaiveThresholds = (): boolean => {
  const settingEnableInsiderWaiveThresholdsXP =
    useSettingEnableInsiderWaiveThresholdsXP();
  const settingEnableInsiderWaiveThresholdsFeatureToggle =
    useSettingEnableInsiderWaiveThresholdsFeatureToggle();
  const settingEnableInsiderWaiveThresholdsEndDate =
    useSettingInsiderWaiveThresholdsEndDate();

  return (
    settingEnableInsiderWaiveThresholdsFeatureToggle &&
    settingEnableInsiderWaiveThresholdsXP &&
    isBefore(settingEnableInsiderWaiveThresholdsEndDate, new Date())
  );
};
