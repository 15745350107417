import { SettingConst } from '@faire/web-api--source/types';

/**
 * Experiment for waiving Insider threshold on opening orders for Insider retailers.
 * PRD: https://www.notion.so/faire/Waive-thresholds-on-opening-orders-17c2efb5c25a80dcbb87cad7aa057c87
 */
export const INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218 =
  'INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218' as SettingConst<
    'INSIDER_THRESHOLD_WAIVING_EXPERIMENT_250218',
    boolean,
    'RETAILER'
  >;
