import { SettingConst } from '@faire/web-api--source/types';

/**
 * The timestamp to end the Insider threshold waiving experiment.
 */
export const INSIDER_THRESHOLD_WAIVING_EXPERIMENT_END_DATE =
  'INSIDER_THRESHOLD_WAIVING_EXPERIMENT_END_DATE' as SettingConst<
    'INSIDER_THRESHOLD_WAIVING_EXPERIMENT_END_DATE',
    string,
    'RETAILER'
  >;
