import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  brandOrderToken: string | undefined;
  finished: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/point-of-sale/order-manager/{orderToken}"
 *
 * Product Area: FAIRESYNC
 */
export const route = '/point-of-sale/order-manager/:orderToken';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/point-of-sale/order-manager/{orderToken}',
  productArea: 'FAIRESYNC',
  pod: 'FAIRE_POD_RETAILER_ORDER_MANAGEMENT',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(
  orderToken: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    orderToken,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  orderToken: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(orderToken, queryParams);
};

export type PathParameters = {
  orderToken: string;
};

export default path;
